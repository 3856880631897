import { useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { calendarApi, useGetCalendarYearlyEventsQuery } from '../../../service';
import { getModalInfo } from '../../../selectors';
import { setModalInfo } from '../../../slice';

import { RTK_HOOK_CONFIG } from '../../../../../../config/constants';
import { ID_KEYS } from '../../../../main/constants';

const ISSUE_MODAL_WIDTH = 410;
const VIEW_MORE_MODAL_WIDTH = 410;

const calclulateLeftValue = (month, shouldDisplayViewMore, position) => {
  const modalWidth = shouldDisplayViewMore
    ? VIEW_MORE_MODAL_WIDTH
    : ISSUE_MODAL_WIDTH;

  if (+month >= 9) {
    return `${
      position?.right -
      30 -
      document.getElementById(ID_KEYS.mainMenu).clientWidth -
      modalWidth
    }px`;
  }
  return `${
    position?.x - 30 - document.getElementById(ID_KEYS.mainMenu).clientWidth
  }px`;
};

const useModals = ({ query }) => {
  const modalInfo = useSelector(getModalInfo);
  const dispatch = useDispatch();

  const modalRef = useRef();
  const scrollPosition = useRef({ scrollTop: 0 });

  // clear modal state after unmount
  useEffect(() => {
    return () => dispatch(setModalInfo({ data: null, position: null }));
  }, [dispatch]);

  const previewItem = useMemo(() => modalInfo.data, [modalInfo.data]);
  const position = useMemo(() => modalInfo.position, [modalInfo.position]);

  const updateModalPosition = useCallback(
    ({ scrollTop, clientHeight }) => {
      // track scroll position
      // when modal is not opened
      if (!previewItem) {
        scrollPosition.current.scrollTop = scrollTop;
      }

      // logic for position of form
      if (modalRef.current) {
        // calculate scroll position
        // as difference between scroll position when modal was opened
        // and current scroll value
        const relativeScrollPosition =
          scrollTop - scrollPosition.current.scrollTop;

        const topPosition = position.y - 135 - relativeScrollPosition + 35;
        modalRef.current.style.top = `${topPosition}px`;

        if (topPosition > clientHeight) {
          modalRef.current.style.display = 'none';
        } else {
          modalRef.current.style.display = 'block';
        }
      }
    },
    [previewItem, position]
  );

  const clearModalData = useCallback(() => {
    dispatch(setModalInfo({ data: null, position: null }));
  }, [dispatch]);

  // # view more data region
  const eventsQuery = useMemo(
    () => ({
      scheduleId: previewItem?.scheduleId,
      page: previewItem?.page,
      paginate_by: previewItem?.paginate_by,
      month: previewItem?.month,
      ...query,
    }),
    [previewItem, query]
  );

  const shouldDisplayViewMore = previewItem?.id === 'all';

  const {
    data: viewAllData,
    isLoading: isLoadingCalendarEvents,
    isFetching: isFetchingCalendarEvents,
  } = useGetCalendarYearlyEventsQuery(eventsQuery, {
    skip: !shouldDisplayViewMore,
    ...RTK_HOOK_CONFIG,
  });

  const updateViewAllData = (item) => {
    dispatch(
      calendarApi.util.updateQueryData(
        'getCalendarYearlyEvents',
        eventsQuery,
        (draft) => {
          if (draft?.data?.length) {
            const index = draft.data.findIndex((evt) => evt.id === item.id);
            if (index !== -1) {
              const event = draft.data[index];
              draft.data[index] = {
                ...event,
                displayType: item.status.type,
                statusId: item.status.id,
              };
            }
          }
        }
      )
    );
  };
  // #endregion

  return {
    modalRef,
    scrollPosition,
    previewItem,
    position,
    updateModalPosition,
    clearModalData,
    shouldDisplayViewMore,

    viewAllData,
    updateViewAllData,
    isLoadingCalendarEvents,
    isFetchingCalendarEvents,

    topValue: `${position?.y - 135 + 35}px`,
    leftValue: calclulateLeftValue(
      previewItem?.month,
      shouldDisplayViewMore,
      position
    ),
  };
};

export default useModals;
