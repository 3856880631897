import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import ActionButton from '../../../../../../components/sticky-header/ActionButton';
import { Menu } from '../../../../../../components_new/Menu';

import { ActionMenuWrapper } from '../styles';

const ActionsMenu = React.forwardRef((props, ref) => {
  const { scheduleId, left, top } = props;

  const history = useHistory();

  return (
    <ActionMenuWrapper ref={ref} $x={left} $y={top}>
      <Menu hardcodedWidth="auto" position="right" distanceX={0} distanceY={0}>
        <ActionButton
          uppercase
          icon="icon-pen"
          variant="menu-item"
          onClick={() =>
            history.push(`/dashboard/scheduling/detail/${scheduleId}`)
          }
          iconSize="18"
        >
          <FormattedMessage id="edit_schedule" />
        </ActionButton>
        <ActionButton
          uppercase
          icon="icon-density-high-mob"
          variant="menu-item"
          onClick={() =>
            history.push(`/dashboard/issues?schedule_id=${scheduleId}`)
          }
          iconSize="17"
        >
          <FormattedMessage id="view_all_scheduled_tasks" />
        </ActionButton>
      </Menu>
    </ActionMenuWrapper>
  );
});

export default ActionsMenu;
