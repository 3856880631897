import { useState } from 'react';
import qs from 'query-string';
import { getToken } from '../../redux/utils';
import { getUserLanguage } from '../../redux/user/selectors';
import { BASE_URL, HTTP_OK } from '../../config/constants';
import { downloadFile } from '../../services/ApiService';
import { formatDateToStandardFormat } from '../../helpers/date.helper';
import { initiateDownload } from '../../utils/utility';
import { getFileNameFromHeaders } from './utils';

const useExport = () => {
  const [isProcessingExport, setIsProcessingExport] = useState(false);

  const exportItems = async ({ url, payload, fileType = 'pdf' } = {}) => {
    setIsProcessingExport(true);
    const token = getToken();
    const language = getUserLanguage();
    const stringifiedUrl = qs.stringifyUrl(
      { url: `${BASE_URL}${url}`, query: payload },
      {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'comma',
      }
    );

    const response = await downloadFile(stringifiedUrl, token);

    if (response?.status === HTTP_OK) {
      const blob = await response.blob();
      const fileName =
        getFileNameFromHeaders(response.headers) ??
        `Export_${formatDateToStandardFormat(
          new Date(),
          language
        )}_wowflow.${fileType}`;
      initiateDownload(blob, fileName);
      setIsProcessingExport(false);
    } else {
      setIsProcessingExport(false);
    }
  };

  return {
    isProcessingExport,
    exportItems,
  };
};

export default useExport;
