import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useInfiniteLazyQuery } from '../../../../../../hooks/useInfinite';
import { useLazyGetCalendarYearlySchedulesQuery } from '../../../service';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigPersistedFilters } from '../../../../../../redux/config/selectors';
import { isEqual } from 'lodash-es';

import {
  CALENDAR_PERSIST_KEYS,
  INIT_QUERY_PARAMS_YEARLY,
  SUPPORTED_PARAMS_YEARLY,
} from '../../../constants';

import useQueryParams from '../../../../../../hooks/useQueryParams';
import { updatePersistedFilters } from '../../../../../../redux/config/actions';

const useYearly = ({ skip, preventReset } = {}) => {
  const dispatch = useDispatch();

  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, CALENDAR_PERSIST_KEYS.YEARLY),
    isEqual
  );

  const paramsConfig = useMemo(() => {
    return {
      init: persistedFilters?.parameters,
      defaultInit: INIT_QUERY_PARAMS_YEARLY,
      supported: SUPPORTED_PARAMS_YEARLY,
      blacklisted: [],
    };
  }, [persistedFilters?.parameters]);

  const { queryParams } = useQueryParams(paramsConfig);

  const _skip = useMemo(() => {
    return (
      skip ||
      !persistedFilters ||
      !queryParams ||
      !Object.keys(queryParams).length
    );
  }, [persistedFilters, queryParams, skip]);

  const _customParams = useMemo(() => {
    return { ...queryParams, paginate_by: 50 };
  }, [queryParams]);

  const { data, isLoading, handleLoadMore, hasMore, query, handleEdit, total } =
    useInfiniteLazyQuery({
      skip: _skip,
      entityKey: 'calendarYearlySchedules',
      useLazyQuery: useLazyGetCalendarYearlySchedulesQuery,
      preferCacheValue: true,
      customParams: _customParams,
      idKey: 'scheduleId',
      preventReset: preventReset,
    });

  const hasDirtyFilters = useMemo(() => {
    return !isEqual(INIT_QUERY_PARAMS_YEARLY, queryParams);
  }, [queryParams]);

  const handleSetCurrentTime = (date) => {
    const mutatedParameters = {
      ...(persistedFilters.parameters ?? {}),
      year: dayjs(date).year(),
    };

    dispatch(
      updatePersistedFilters(mutatedParameters, CALENDAR_PERSIST_KEYS.YEARLY)
    );
  };

  return {
    data,
    isLoading,
    handleLoadMore,
    hasMore,
    query,
    handleEdit,
    page: queryParams.page,
    paginateBy: queryParams.paginate_by,
    sortBy: queryParams.sort_by,
    sortDirection: queryParams.sort_direction,
    queryParams,
    hasDirtyFilters,
    year: queryParams?.year ?? `${dayjs().year()}`,
    handleSetCurrentTime,
    total,
  };
};

export default useYearly;
