import styled from 'styled-components/macro';
import { darkerGray, darkGray } from '../../config/colors';

export const MainContent = styled.div`
  padding: 1rem 4rem;
  text-align: center;
  > div {
    position: relative;
    top: 30%;
    > svg {
      position: relative;
    }
  }
`;

export const Title = styled.h4`
  color: ${darkGray};
  font-size: 22px;
  margin-bottom: 1rem;
`;

export const Message = styled.span`
  color: ${darkerGray};
`;
