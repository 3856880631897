import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';

import { internalRoutePaths } from '../../../../../config/config';
import {
  appendGalleryFormData,
  addAttachmentsForIssue,
} from '../../../IssuesV2/Issue/utils';
import {
  getTimelineIssueCurrentDragId,
  getUnscheduledIssueCurrentDragId,
} from '../../selectors';
import {
  DEFAULT_PROFILE_IMAGE,
  RTK_HOOK_CONFIG,
} from '../../../../../config/constants';
import {
  statusColors,
  gray,
  darkGray,
  futureTaskIssueModalCalendar,
} from '../../../../../config/colors';
import {
  setTimelineIssueCurrentDragId,
  setTimelineIssueDraggableElementId,
  setUnscheduledIssueCurrentDragId,
} from '../../slice';
import {
  useGetCalendarIssueQuery,
  useUpdateCalendarIssueMutation,
  useGetCalendarScheduledTaskQuery,
  useUpdateCalendarScheduledTaskMutation,
  useGetScheduledTaskFuturePreviewQuery,
} from '../../service';
import {
  RAW_ISSUE_STATUSES,
  FORM_DEFAULT_VALUES,
} from '../../../IssuesV2/Issue/constants';
import { ISSUE_ITEM_ACTION_OPTIONS } from '../../../IssuesV2/constants';
import { useOldGalleryUpdateIssueAttachmentsMutation } from '../../../IssuesV2/Issue/service';
import { getIssueImages } from '../../../../../helpers/issue.helper';
import { formatTimestampFullDateAndTime } from '../../../../../helpers/date.helper';
import { parseIssueData, formValidation } from '../../../IssuesV2/Issue/utils';
import { actions as appActions } from '../../../../../redux/app/actions';
import { selectAttachment } from '../../../../../redux/gallery/actions';

import {
  WowIcon,
  WowLink,
} from '../../../../../components_new/WowBasicElements';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';
import ImageCarousel from '../../../../../components/image-carousel/image-carousel.component';
import IssueActionItems from '../../../IssuesV2/components/ItemActions';
import FieldsWrapper from './components/FieldsWrapper';
import GalleryWrapper from './components/GalleryWrapper';

import {
  FormWrapper,
  Wrapper,
  DetailsWrapper,
  ImageWrapper,
  ActionBarWrapper,
  StatusWrapper,
  AssigneeAvatar,
  StatusBox,
  ActionButtonsWrapper,
  HeaderDetails,
  IssueTitle,
  WorkspaceRow,
  CategoryText,
  WorkspaceText,
  StatsDetails,
  SingleStatsWrapper,
  SingleStatsText,
  DescriptionWrapper,
  BorderBottom,
  ScheduledIssueBox,
} from './styles';
import FieldsActionButtonsWrapper from './components/FieldsActionButtonsWrapper';
import {
  limitNumberOfCharacters,
  openInNewTab,
} from '../../../../../utils/core';
import { DESCRIPTION_LIMIT, SELECTOR_MODAL_USERS } from './constants';
import AssetSection from '../../../IssuesV2/Issue/components/AddEditWrapper/components/SectionLeft/AssetSection';

const calendarModalIssueDetailsActionsClasses = {
  quickComplete: ['__calendarQuickCompleteModal'],
  delete: ['__calendarDeleteModal'],
};

const IssueDetailsModal = React.forwardRef((props, ref) => {
  const {
    id,
    onClose = () => {},
    handleUpdateDataItem = () => {},
    handleDeleteDataItem = () => {},
    overrideData = {},
    isSetup,
    removeItemsFromUnscheduledSidebar = () => {},
    leftValue = null,
    bottomValue = null,
    isScheduledPreparedTask,
    draggableTimelineIssueRevertData,
    weeklyDndUpdateDropCancelTrigger,
    rightValue = null,
    topValue = null,
  } = props;

  const intl = useIntl();
  const initialSet = useRef(false);
  const mainWrapper = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const isFutureReadOnlyTask =
    overrideData?.status_id === 0 || overrideData?.statusId === 0;

  /*
  // https://adagedev.atlassian.net/browse/WOW-6021 when start working on this one, uncomment and continue. Scroll should be locked when toggled.
  useEffect(() => {
    //Disable scroll container when this modal is open.
    const contentElem = document.getElementsByClassName('content')?.[0];
    if (contentElem) contentElem.style.overflow = 'hidden';
    return () => {
      if (contentElem) contentElem.style.overflow = null;
    };
  }, []);
  */

  const unscheduledIssueCurrentDragId = useSelector(
    getUnscheduledIssueCurrentDragId
  );
  const timelineIssueCurrentDragId = useSelector(getTimelineIssueCurrentDragId);

  const gallery = useSelector((state) => state.gallery);
  const galleryPopupPresented = useSelector(
    (state) => state?.app?.toJS()?.galleryPopupPresented
  );

  const [oldGalleryUpdateAttachments] =
    useOldGalleryUpdateIssueAttachmentsMutation();

  const [skipCalendarIssueFetch, setSkipCalendarIssueFetch] = useState(true);
  const [skipCalendarScheduledTaskFetch, setSkipCalendarScheduledTaskFetch] =
    useState(true);

  const {
    data: calendarIssue,
    isLoading: isLoadingCalendarIssue,
    refetch: refetchCalendarIssue,
    isUninitialized: isUninitializedCalendarIssue,
  } = useGetCalendarIssueQuery(id, {
    skip: skipCalendarIssueFetch,
    ...RTK_HOOK_CONFIG,
  });

  const {
    data: calendarScheduledTask,
    isLoading: isLoadingCalendarScheduledTask,
    refetch: refetchCalendarScheduledTask,
    isUninitialized: isUninitializedCalendarScheduledTask,
  } = useGetCalendarScheduledTaskQuery(id, {
    skip: skipCalendarScheduledTaskFetch,
    ...RTK_HOOK_CONFIG,
  });

  const {
    data: calendarScheduledTaskFuturePreview,
    isLoading: isLoadingScheduledTaskFuturePreview,
  } = useGetScheduledTaskFuturePreviewQuery(id, {
    skip: !isFutureReadOnlyTask,
    ...RTK_HOOK_CONFIG,
  });

  const [updateIssue, { isLoading: isUpdateIssueLoading }] =
    useUpdateCalendarIssueMutation();

  const [updateScheduledTask, { isLoading: isUpdateScheduledTaskLoading }] =
    useUpdateCalendarScheduledTaskMutation();

  useEffect(() => {
    if (!isFutureReadOnlyTask) {
      if (isScheduledPreparedTask) setSkipCalendarScheduledTaskFetch(false);
      else setSkipCalendarIssueFetch(false);
    }
  }, [isScheduledPreparedTask, isFutureReadOnlyTask]);

  const issue = isFutureReadOnlyTask
    ? calendarScheduledTaskFuturePreview
    : calendarIssue || calendarScheduledTask;
  const isLoadingIssue =
    isLoadingCalendarIssue ||
    isLoadingCalendarScheduledTask ||
    isLoadingScheduledTaskFuturePreview;

  const formMethods = useForm({
    resolver: vestResolver(formValidation),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { isDirty, dirtyFields } = formMethods.formState;

  const submitHandler = async (data) => {
    const { ...restData } = data;
    const fd = new FormData();
    const preparedData = { ...restData };
    if (preparedData.start_date)
      preparedData.start_date = parseInt(data.start_date / 1000);
    if (preparedData.due_date)
      preparedData.due_date = parseInt(data.due_date / 1000);

    delete preparedData.process_number;
    delete preparedData.external_watcher_email_address;
    if (
      preparedData.external_email_address === '' ||
      preparedData.external_email_address === null
    )
      delete preparedData.external_email_address;

    const sameAssignee = preparedData?.assignee_id === issue?.assignee_id;
    let oldAssignee;
    if (!sameAssignee) oldAssignee = issue?.assignee_id;

    if (draggableTimelineIssueRevertData)
      draggableTimelineIssueRevertData.current = null;

    const galleryData =
      gallery && gallery.get(`gallery_${preparedData?.id ?? 'default'}`)
        ? gallery.get(`gallery_${preparedData?.id ?? 'default'}`).toJS()
        : null;
    if (galleryData?.attachments?.length) {
      fd.append('_method', 'PUT');
      appendGalleryFormData(fd, galleryData?.attachments);
    }

    let updateIssueResponse;
    if (isScheduledPreparedTask) {
      updateIssueResponse = await updateScheduledTask({
        id: preparedData.id,
        body: preparedData,
      });
    } else {
      updateIssueResponse = await updateIssue({
        id: preparedData.id,
        body: preparedData,
      });
    }
    const updateIssueResponseData =
      updateIssueResponse?.data?.scheduled_task ??
      updateIssueResponse?.data?.issue ??
      {};

    if (galleryData?.attachments?.length && dirtyFields?.attachments) {
      await oldGalleryUpdateAttachments({
        id: preparedData.id,
        formData: fd,
      });
    }

    if (updateIssueResponse) {
      formMethods.reset({ ...parseIssueData(updateIssueResponseData) });
      handleUpdateDataItem(
        { ...updateIssueResponseData, _setup: false },
        {
          sameAssignee,
          oldAssignee,
          unscheduledSave: unscheduledIssueCurrentDragId,
        }
      );
      if (updateIssueResponseData.id)
        removeItemsFromUnscheduledSidebar([updateIssueResponseData.id]);
    }
    dispatch(setUnscheduledIssueCurrentDragId(null));
    dispatch(setTimelineIssueCurrentDragId(null));
    dispatch(setTimelineIssueDraggableElementId(null));
    window.setTimeout(() => onClose({ id: preparedData.id }), 0);
  };

  const handleQuickCompleteSuccessCallback = (data) => {
    if (draggableTimelineIssueRevertData)
      draggableTimelineIssueRevertData.current = null;
    if (data?.id) {
      handleUpdateDataItem({
        ...issue,
        id: data.id,
        completed_at: Date.now() / 1000,
        status: RAW_ISSUE_STATUSES[8],
        status_id: 8,
        isQuickComplete: true,
      });
      if (refetchCalendarIssue && !isUninitializedCalendarIssue)
        refetchCalendarIssue();
      if (refetchCalendarScheduledTask && !isUninitializedCalendarScheduledTask)
        refetchCalendarScheduledTask();
      window.setTimeout(() => onClose({ id: data.id }), 0);
    }
  };

  const handleDeleteSuccessCallback = (data) => {
    if (draggableTimelineIssueRevertData)
      draggableTimelineIssueRevertData.current = null;
    if (data?.id) {
      handleDeleteDataItem(data?.id);
      window.setTimeout(() => onClose({ id: data.id }), 0);
    }
  };

  useEffect(() => {
    if (issue && !initialSet.current) {
      initialSet.current = true;
      if (overrideData.title === null) delete overrideData.title;
      formMethods.reset(parseIssueData({ ...issue, ...overrideData }));
      if (issue?.attachments?.length) addAttachmentsForIssue(issue, dispatch);
    }
  }, [overrideData, issue, formMethods, dispatch]);

  const handleClickOutside = useCallback(
    (event) => {
      if (galleryPopupPresented || timelineIssueCurrentDragId) return;
      // Because we have popup inside popup so we need to handle these cases too...
      const deleteModal = document.getElementsByClassName(
        calendarModalIssueDetailsActionsClasses.delete[0]
      )?.[0];
      const quickCompleteModal = document.getElementsByClassName(
        calendarModalIssueDetailsActionsClasses.quickComplete[0]
      )?.[0];
      const issueAssigneeModalAdd =
        document.querySelector(SELECTOR_MODAL_USERS);
      if (
        mainWrapper.current &&
        !mainWrapper.current.contains(event.target) &&
        (!deleteModal || !deleteModal.contains(event.target)) &&
        (!quickCompleteModal || !quickCompleteModal.contains(event.target)) &&
        id &&
        !isDirty &&
        !issueAssigneeModalAdd
      ) {
        onClose({ id });
      }
    },
    [id, isDirty, onClose, galleryPopupPresented, timelineIssueCurrentDragId]
  );
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore((prevState) => !prevState);
  const images = useMemo(() => getIssueImages(issue), [issue]);
  const hasImages = images && images.length > 0;
  const isUpdateLoading = isUpdateIssueLoading || isUpdateScheduledTaskLoading;

  const openIssueDetails = () =>
    openInNewTab(internalRoutePaths.ISSUES_ID.replace(':id', issue?.id));

  const onImageClick = (e) => {
    const src = e?.target?.src;
    if (!src) return;
    const image = issue?.attachments?.find(
      (item) => item.web_mob_thumbnail_url === src || item.url === src
    );
    if (!image) return;
    dispatch(appActions.toggleGalleryPopup());
    dispatch(selectAttachment(image, issue?.id));
  };

  return (
    <FormProvider {...formMethods}>
      <FormWrapper
        position={galleryPopupPresented ? 'static' : 'absolute'}
        onSubmit={(e) => {
          if (!e || !e.target) return;
          e.preventDefault();
          formMethods.handleSubmit(submitHandler)();
        }}
        left={leftValue}
        right={rightValue}
        bottom={bottomValue}
        top={topValue}
        ref={ref}
      >
        <Wrapper
          ref={mainWrapper}
          data-testid="issueDetailsModalWrapper"
          style={{
            backgroundColor: isFutureReadOnlyTask
              ? futureTaskIssueModalCalendar
              : null,
          }}
        >
          {isLoadingIssue ? (
            <LoadingSpinner />
          ) : (
            <>
              {hasImages ? (
                <ImageWrapper>
                  <ImageCarousel images={images} onImageClick={onImageClick} />
                </ImageWrapper>
              ) : null}
              <DetailsWrapper>
                <ActionBarWrapper>
                  <StatusWrapper>
                    <AssigneeAvatar
                      src={
                        issue?.assignee?.profile_image ?? DEFAULT_PROFILE_IMAGE
                      }
                      alt={issue?.assignee?.full_name}
                    />
                    {issue?.schedule_id && (
                      <ScheduledIssueBox
                        onClick={() =>
                          history.push(
                            internalRoutePaths.SCHEDULING_ID.replace(
                              ':id',
                              issue.schedule_id
                            )
                          )
                        }
                      >
                        <WowIcon
                          className="icon-scheduling"
                          color="#fff"
                          size="13"
                          spaceRight="0"
                          spaceLeft="0"
                          mRight="0"
                          style={{ position: 'relative', left: 4.5 }}
                        />
                      </ScheduledIssueBox>
                    )}
                    {!isFutureReadOnlyTask && (
                      <StatusBox
                        backgroundColor={
                          statusColors[issue?.status?.name ?? 'gray']
                        }
                      >
                        {intl.formatMessage({
                          id:
                            RAW_ISSUE_STATUSES[
                              (issue?.status_id || issue?.statusId) ?? 2
                            ]?.name ?? ' ',
                        })}

                        {issue?.archived ? (
                          <>
                            {' - '} {intl.formatMessage({ id: 'archived' })}
                          </>
                        ) : null}
                      </StatusBox>
                    )}
                  </StatusWrapper>
                  {!isFutureReadOnlyTask && (
                    <ActionButtonsWrapper data-testid="calendarIssueDetailsActionButtonsWrapper">
                      {issue?.id && !isScheduledPreparedTask ? (
                        <WowIcon
                          className="icon-export"
                          color={darkGray}
                          size="22"
                          spaceRight="0"
                          spaceLeft="0"
                          onClick={openIssueDetails}
                          mRight="5"
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        ''
                      )}
                      {!isSetup && !isScheduledPreparedTask && (
                        <IssueActionItems
                          item={issue ?? {}}
                          showOptionList={[
                            ISSUE_ITEM_ACTION_OPTIONS.QUICK_COMPLETE_ISSUE,
                            ISSUE_ITEM_ACTION_OPTIONS.DELETE_ISSUE,
                          ]}
                          permissions={{
                            canQuickCompleteIssue: issue && !issue.completed_at,
                            canReassignIssue: false,
                          }}
                          actionCallbacks={{
                            quickCompleteSuccessCallback:
                              handleQuickCompleteSuccessCallback,
                            deleteSuccessCallback: handleDeleteSuccessCallback,
                          }}
                          modalClasses={calendarModalIssueDetailsActionsClasses}
                        />
                      )}
                    </ActionButtonsWrapper>
                  )}
                </ActionBarWrapper>
                <HeaderDetails>
                  {isFutureReadOnlyTask && (
                    <WorkspaceText>
                      {intl.formatMessage({
                        id: 'preview_future_task',
                      })}
                    </WorkspaceText>
                  )}
                  <IssueTitle
                    onClick={isFutureReadOnlyTask ? () => {} : openIssueDetails}
                  >
                    {issue?.title}
                  </IssueTitle>
                  <WorkspaceRow>
                    {issue?.category?.name && (
                      <CategoryText>{issue?.category?.name}</CategoryText>
                    )}
                    <WorkspaceText>{issue?.workspace?.name}</WorkspaceText>
                  </WorkspaceRow>
                </HeaderDetails>
                <StatsDetails>
                  <SingleStatsWrapper>
                    <WowIcon
                      className="icon-comment"
                      color={gray}
                      size="13"
                      spaceRight="0"
                      spaceLeft="0"
                      mRight="5"
                    />
                    <SingleStatsText>
                      {issue?.number_of_comments}
                    </SingleStatsText>
                  </SingleStatsWrapper>
                  {issue?.due_date && (
                    <SingleStatsWrapper>
                      <WowIcon
                        className="icon-time"
                        color={gray}
                        size="13"
                        spaceRight="0"
                        spaceLeft="0"
                        mRight="5"
                        style={{ position: 'relative', bottom: 1 }}
                      />
                      <SingleStatsText>
                        {formatTimestampFullDateAndTime(issue.due_date * 1000)}
                      </SingleStatsText>
                    </SingleStatsWrapper>
                  )}
                </StatsDetails>

                <AssetSection
                  modalClasses={calendarModalIssueDetailsActionsClasses}
                  wrapperStyle={{ padding: '5px 0' }}
                  showHeader={false}
                  showStatistics={false}
                />

                {issue?.description && (
                  <DescriptionWrapper>
                    {limitNumberOfCharacters(
                      issue?.description,
                      showMore ? issue?.description.length : DESCRIPTION_LIMIT
                    )}
                    {issue.description.length > DESCRIPTION_LIMIT ? (
                      <WowLink onClick={toggleShowMore}>
                        {' '}
                        <FormattedMessage
                          id={showMore ? 'show_less' : 'show_more'}
                        />
                      </WowLink>
                    ) : null}
                  </DescriptionWrapper>
                )}
                <BorderBottom />
              </DetailsWrapper>
              <FieldsWrapper
                workspace={issue?.workspace}
                isFutureReadOnlyTask={isFutureReadOnlyTask}
                issue={issue}
              />
              {!isFutureReadOnlyTask && isDirty && (
                <FieldsActionButtonsWrapper
                  disableSubmit={isUpdateLoading}
                  isLoading={isUpdateLoading}
                  onClose={() => {
                    onClose({
                      id: issue?.id,
                      isSetup,
                      presetTopValue: 0,
                      weeklyDndUpdateDropCancelTrigger,
                    });
                    dispatch(setUnscheduledIssueCurrentDragId(null));
                    dispatch(setTimelineIssueCurrentDragId(null));
                    dispatch(setTimelineIssueDraggableElementId(null));
                    if (draggableTimelineIssueRevertData)
                      draggableTimelineIssueRevertData.current = null;
                  }}
                />
              )}
            </>
          )}
        </Wrapper>
        {issue && !isFutureReadOnlyTask ? (
          <GalleryWrapper
            issue={parseIssueData(issue)}
            refetchCalendarIssue={refetchCalendarIssue}
          />
        ) : null}
      </FormWrapper>
    </FormProvider>
  );
});

export default IssueDetailsModal;
