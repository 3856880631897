export const MAIN_MENU_OPTIONS = [
  {
    id: 1,
    value: 'yearly',
    label: 'Yearly Schedules',
    translationKey: 'yearly_schedules',
    isBeta: false,
  },
  {
    id: 2,
    value: 'weeklyUsers',
    label: 'Weekly - Users',
    translationKey: 'weekly_user',
    isBeta: true,
  },
  {
    id: 3,
    value: 'weeklyWorkspaces',
    label: 'Weekly - Workspaces',
    translationKey: 'weekly_workspace',
    isBeta: true,
  },
  {
    id: 4,
    value: 'dailyUsers',
    label: 'Day - Users',
    translationKey: 'daily_user',
    isBeta: true,
  },
  {
    id: 5,
    value: 'dailyWorkspaces',
    label: 'Day - Workspaces',
    translationKey: 'daily_workspaces',
    isBeta: true,
  },
];

export const YEARLY_SORT_OPTIONS = [
  {
    value: 'title',
    label: 'title',
  },
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
];

export const YEARLY_LIMIT_MIN_YEAR = 2020;

export const YEARLY_SUPPORTED_EXPORT_KEYS = [
  'year',
  'sort_by',
  'sort_direction',
  'search',
  'workspaces',
  'assignees',
  'asset_ids',
  'asset_system_ids',
  'categories',
];
