import { FormattedMessage, useIntl } from 'react-intl';
import Modal, { SIZE } from '../Modal';
import { MainContent, Message, Title } from './styles';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';

const ModalExport = (props) => {
  const { isOpen, handleClose, title } = props;

  const intl = useIntl();

  return (
    <Modal
      title={`${
        title ??
        intl.formatMessage({
          id: 'export',
        }) + ' PDF'
      }`}
      isOpen={isOpen}
      onClose={handleClose}
      size={SIZE.medium}
    >
      <MainContent data-testid="loadingModal">
        <LoadingSpinner isLocal />
        <Title>
          <FormattedMessage id="in_progress" />
        </Title>
        <Message>
          <FormattedMessage id="file_download_in_progress" />
        </Message>
      </MainContent>
    </Modal>
  );
};

export default ModalExport;
