export const getFileNameFromHeaders = (headers) => {
  const contentDisposition = headers?.get('content-disposition');

  if (contentDisposition) {
    const parts = contentDisposition.split(';');
    const filenamePart = parts.find((part) =>
      part.trim().startsWith('filename=')
    );
    if (filenamePart) {
      return filenamePart.split('=')[1].trim().replace(/"/g, '');
    }
  }

  return null;
};
