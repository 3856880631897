import { useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { WowIcon } from '../../../../../../../components_new/WowBasicElements';
import { mainAccent } from '../../../../../../../helpers/theme';
import useExport from '../../../../../../../hooks/useExport';
import { buttonDisabled } from '../../../../../../../config/colors';
import ModalExport from '../../../../../../../components_new/ModalExport';
import { YEARLY_SUPPORTED_EXPORT_KEYS } from '../../constants';

const Export = ({ isDisabled }) => {
  const { search } = useLocation();
  const [isVisibleExportModal, setIsVisibleExportModal] = useState(false);
  const handleModalClose = () => setIsVisibleExportModal(false);
  const { exportItems, isProcessingExport } = useExport();

  const handleExport = async () => {
    if (isProcessingExport || isDisabled) return;

    const payload = qs.parse(search) ?? {};
    const keysSet = new Set(YEARLY_SUPPORTED_EXPORT_KEYS);

    const formattedPayload = Object.fromEntries(
      Object.entries(payload).filter(([key]) => keysSet.has(key))
    );

    setIsVisibleExportModal(true);
    await exportItems({
      url: '/calendar/yearly/export',
      payload: formattedPayload,
    });
    setIsVisibleExportModal(false);
  };

  return (
    <>
      <WowIcon
        className="icon icon-download"
        display="flex"
        color={isProcessingExport || isDisabled ? buttonDisabled : mainAccent}
        spaceRight="10"
        spaceLeft="10"
        size="25"
        onClick={handleExport}
        clickable={!isProcessingExport && !isDisabled}
      />

      {isVisibleExportModal ? (
        <ModalExport isOpen={true} handleClose={handleModalClose} />
      ) : null}
    </>
  );
};

export default Export;
