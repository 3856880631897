import styled from 'styled-components/macro';
import { darkerGray, hardWhite, userGray } from '../../../../config/colors';
import { breakpoint } from '../../../../styles/styledComponents/media-breakpoints';

export const LogoLoader = styled.div`
  position: relative;
  width: 200px;
  margin-right: 80px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LogoImage = styled.img`
  max-height: 26px;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginMessage = styled.span`
  @media only screen and ${breakpoint.sm} {
    display: none;
  }

  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  color: ${userGray};
`;

export const CalendarButton = styled.button`
  border-radius: 18px;
  background-color: ${hardWhite};
  margin-right: 15px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  color: ${darkerGray};
  font-size: 14px;
  font-weight: bold;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderInnerContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  @media only screen and (min-width: 2000px) {
    && {
      width: 1900px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
