import styled from 'styled-components/macro';

import {
  gray,
  darkGray,
  filtersAdvancedGray,
} from '../../../../../config/colors';

export const Wrapper = styled.div`
  max-height: 375px;
  width: 410px;
  box-shadow: 3px -1px 15px 1px ${gray};
  border-radius: 10px;
  background-color: #fff;
  z-index: 99;
  ${({ position }) =>
    position ? `position: ${position};` : 'position: absolute;'}
  ${({ bottom }) => (bottom !== null ? `bottom: ${bottom};` : '')}
  ${({ top }) => (top !== null ? `top: ${top};` : '')}
  ${({ left }) => (left !== null ? `left: ${left};` : '')}
  ${({ right }) => (right !== null ? `right: ${right};` : '')}
`;

export const DetailsWrapper = styled.div`
  padding: 20px;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const ItemWrapper = styled.div`
  position: relative;
`;

export const DateStringWrapper = styled.div`
  text-align: center;
  font-size: 25px;
  color: ${filtersAdvancedGray};
`;

export const YearStringWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
  color: ${darkGray};
`;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 50px;
`;
