import styled from 'styled-components/macro';

import {
  darkGray,
  white,
  filtersAdvancedGray,
  purple500,
  newBorder,
  hardWhite,
} from '../../../../../config/colors';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  z-index: 1;
  box-shadow: 0px 2px 5px #37465f26;

  height: 50px;
  padding: 0px 25px;
  border-radius: 8px 8px 0px 0px;

  position: sticky;
  top: 78px;

  background-color: ${white};
`;

export const MainTitle = styled.div`
  width: 300px;

  font-size: 13px;
  line-height: 18px;
  color: ${filtersAdvancedGray};
`;

export const Title = styled.div`
  width: 64px;
  text-transform: uppercase;
  text-align: center;

  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: ${darkGray};
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectedTitle = styled.div`
  width: 54px;
  height: 23px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: ${white};
  background: ${purple500};
  padding: 4px 16px;
`;

export const Content = styled.div`
  padding: 0px 25px 25px;
  height: ${(props) =>
    props.$isLoading ? 'calc(100vh - 320px)' : 'calc(100vh - 220px)'};

  .schedule-row:last-child {
    margin-bottom: 1px;
  }
`;

export const CellContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 6px;

  position: relative;
`;

export const CellItem = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  ${(props) =>
    props?.$background ? `background-color: ${props?.$background};` : ''}
  ${(props) =>
    props?.$borderColor
      ? `border: 2px solid ${props?.$borderColor}; border-radius: 2px;`
      : ''}

  cursor: pointer;

  text-align: center;
  color: ${({ color }) => color ?? white};
  font-size: 12px;
  font-weight: bold;

  span {
    display: inline-block;
    margin-top: 2px;
  }

  ${({ isActive }) =>
    isActive
      ? `:before {
    content: '';
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 3px;
    background-color: white;
    opacity: 0.5;
    left: 2px; 
    top: 2px;
  }`
      : null};
`;

export const CellItemInnter = styled.div`
  background-color: white;
  margin: 2px;
  border-radius: 3px;
  height: calc(100% - 4px);
  opacity: ${({ opacity }) => opacity ?? 0};
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  i:hover {
    color: ${purple500};
  }
`;

export const Row = styled.div`
  height: 36px;

  display: flex;

  border-bottom: 1px solid ${newBorder};
`;

export const TitleColumn = styled.div`
  width: 300px;
  height: 36px;
  padding: 8px 6px;

  display: flex;
  align-item: center;
  justify-content: space-between;

  border-right: 1px solid ${newBorder};

  .title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600px;
    color: ${darkGray};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
`;

export const CellWrapper = styled.div`
  width: 64px;
  padding: 8px 6px;
  flex: 1;

  ${(props) => (props.$isCurrent ? `background-color: ${hardWhite};` : null)}
  ${(props) =>
    !props.$isLast ? `border-right: 1px solid ${newBorder};` : null}
`;

export const ShowMoreLoaderWrapper = styled.div`
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  > div {
    position: relative;
    transform: none;
    top: auto;
    left: auto;
  }
`;

export const ActionMenuWrapper = styled.div`
  position: absolute;
  min-width: 220px;
  width: fit-content;
  ${({ $x }) => ($x ? `left: ${$x}` : null)};
  ${({ $y }) => ($y ? `top: ${$y}` : null)};
  background-color: transparent;
`;
