import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Space from '../../../../../components_new/Space';
import Switch from '../../../../../components_new/Switch/Switch';
import { getCurrentTimeMarkerPosition } from '../DailyTimeline/utils';
import StickyHeader, {
  HeaderSection,
  Title,
  Sort,
  Search,
} from '../../../../../components_new/StickyHeader';
import MainDropdown from '../../../../../components_new/StickyHeader/MainDropdown';
import {
  WowDisplayFlex,
  WowIcon,
} from '../../../../../components_new/WowBasicElements';
import {
  darkGray,
  purple500,
  white,
  filtersAdvancedGray,
} from '../../../../../config/colors';
import { ButtonWithIcon, TodayButton } from '../../styles';
import {
  MAIN_MENU_OPTIONS,
  YEARLY_LIMIT_MIN_YEAR,
  YEARLY_SORT_OPTIONS,
} from './constants';
import { MONTH_KEYS } from '../../../../../config/config';
import {
  BLACKLISTED_FILTERS,
  CALENDAR_FILTERS_PERSIST_KEY,
  CALENDAR_PERSIST_KEYS,
  CALENDAR_VIEW_TYPES,
  INIT_QUERY_PARAMS,
  INIT_QUERY_PARAMS_YEARLY,
} from '../../constants';
import { getActiveView, getIsUnscheduledMenuOpen } from '../../selectors';
import { updateUnscheduledMenuOpen } from '../../slice';
import useWindowSizeDebounced from '../../../../../hooks/useWindowSizeDebounced';
import CalendarDatePicker from './components/CalendarDatePicker';
import useWeeklyConfig from '../WeeklyTimeline/hooks/useConfig';

import dict from '../../../../../config/multilang';
import ListFilters from '../../../../../components_new/ListFilters';
import {
  ListStickyHeaderOuterSectionWrapper,
  ListStickyHeaderSectionWrapper,
} from '../../../../../components_new/StickyHeader/StickyHeader';
import useFilters from '../../../../../components_new/ListFilters/hooks/useFilters';
import FilterActions from './components/FilterActions';
import Export from './components/Export';
import { useDebounce } from '../../../../../hooks/useDebounce';

const Header = (props) => {
  const {
    setActiveView = () => {},
    currentTime = new Date(),
    setCurrentTime = () => {},
    setShowWeekend = () => {},
    showWeekend,
    weeklyDays = [],
    isExportDisabled,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const history = useHistory();

  const activeView = useSelector(getActiveView);

  const {
    sortBy,
    sortDirection,
    setSortBy,
    setSortDirection,
    search,
    setSearch,
  } = useFilters({
    entity: CALENDAR_PERSIST_KEYS.YEARLY,
    skip: true,
  });

  const isUnscheduledMenuOpen = useSelector(getIsUnscheduledMenuOpen);
  const handleUnscheduledMenuOpen = () =>
    dispatch(updateUnscheduledMenuOpen(!isUnscheduledMenuOpen));

  const [showCalendarDatePicker, setShowCalendarDatePicker] = useState();

  const { width } = useWindowSizeDebounced();
  const isQHDResolution = width >= 2560;
  const shouldShowOnlyOneSidebar = width < 1600;
  const isUnscheduledMenuActive =
    isUnscheduledMenuOpen && !shouldShowOnlyOneSidebar && !isQHDResolution;

  const { eachHourHeightPxBox } = useWeeklyConfig();

  const showTime = useCallback(() => {
    if (
      activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    )
      return `${intl.formatMessage({
        id: MONTH_KEYS?.[currentTime.getMonth() + 1] ?? ' ',
      })} ${currentTime.getDate()}, ${currentTime.getFullYear()}`;

    if (
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      return `${intl
        .formatMessage({
          id: MONTH_KEYS?.[currentTime.getMonth() + 1] ?? ' ',
        })
        .slice(0, 3)} 
        ${new Date(weeklyDays[0]).getDate()}
        ${' - '}
        ${
          new Date(weeklyDays[weeklyDays.length - 1]).getMonth() !==
          currentTime.getMonth()
            ? intl
                .formatMessage({
                  id:
                    MONTH_KEYS?.[
                      new Date(weeklyDays[weeklyDays.length - 1]).getMonth() + 1
                    ] ?? ' ',
                })
                .slice(0, 3)
            : ''
        }
        ${new Date(weeklyDays[weeklyDays.length - 1]).getDate()}${', '}
        ${currentTime.getFullYear()}`;
    }

    if (activeView === CALENDAR_VIEW_TYPES.yearly)
      return `${currentTime.getFullYear()}`;
  }, [activeView, currentTime, intl, weeklyDays]);

  const handleArrows = useCallback(
    (next = false) => {
      const prevState = currentTime;
      let newDate = new Date(
        prevState.getFullYear(),
        prevState.getMonth(),
        prevState.getDate(),
        prevState.getHours(),
        prevState.getMinutes(),
        prevState.getSeconds()
      );
      if (
        activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
        activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        if (next) newDate.setDate(newDate.getDate() + 1);
        else newDate.setDate(newDate.getDate() - 1);
      } else if (
        activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        const startWeek = new Date(dayjs(newDate).startOf('isoWeek'));
        if (next) startWeek.setDate(startWeek.getDate() + 7);
        else startWeek.setDate(startWeek.getDate() - 7);
        newDate = startWeek;
      } else if (activeView === CALENDAR_VIEW_TYPES.yearly) {
        if (next)
          newDate = new Date(dayjs(newDate).add(1, 'year').startOf('year'));
        else {
          newDate = new Date(
            dayjs(newDate).subtract(1, 'year').startOf('year')
          );
        }
      }
      setCurrentTime(newDate);
    },
    [activeView, setCurrentTime, currentTime]
  );

  const handleCalendarPick = (time = new Date()) => {
    if (
      activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    ) {
      setCurrentTime(new Date(time));
    } else if (
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      const startWeek = new Date(dayjs(time).startOf('isoWeek'));
      setCurrentTime(new Date(startWeek));
    }
  };

  const setToday = () => {
    if (
      activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      const prevState = new Date();
      let newDate = new Date(
        prevState.getFullYear(),
        prevState.getMonth(),
        prevState.getDate(),
        prevState.getHours(),
        prevState.getMinutes(),
        prevState.getSeconds()
      );
      const startWeek = new Date(dayjs(newDate).startOf('isoWeek'));
      setCurrentTime(startWeek);
      window.setTimeout(() => {
        const contentElem = document.getElementsByClassName('content')?.[0];
        if (contentElem) {
          contentElem.scrollTop =
            getCurrentTimeMarkerPosition({
              eachHourHeightPxBox,
            }) - 50;
        }
      }, 0);
    } else {
      setCurrentTime(new Date());
    }
  };

  const showPrevArrow = useMemo(() => {
    if (activeView === CALENDAR_VIEW_TYPES.yearly) {
      return currentTime.getFullYear() > YEARLY_LIMIT_MIN_YEAR;
    }

    return true;
  }, [activeView, currentTime]);

  const showNextArrow = useMemo(() => {
    const currentYear = new Date().getFullYear();

    if (activeView === CALENDAR_VIEW_TYPES.yearly) {
      return currentTime.getFullYear() < currentYear + 1;
    }

    return true;
  }, [activeView, currentTime]);

  const filterActionsConfig = useMemo(() => {
    const persistedFiltersKey =
      activeView === CALENDAR_VIEW_TYPES.yearly
        ? CALENDAR_PERSIST_KEYS.YEARLY
        : CALENDAR_PERSIST_KEYS.CALENDAR;
    const initQueryParams =
      activeView === CALENDAR_VIEW_TYPES.yearly
        ? INIT_QUERY_PARAMS_YEARLY
        : INIT_QUERY_PARAMS;
    return {
      persistedFiltersKey,
      initQueryParams,
    };
  }, [activeView]);

  const filtersConfig = useMemo(() => {
    return {
      entity: CALENDAR_FILTERS_PERSIST_KEY[activeView],
      blacklisted: BLACKLISTED_FILTERS[activeView],
    };
  }, [activeView]);

  const [_search, _setSearch] = useState(search ?? '');
  const debouncedSearch = useDebounce(_search, 1000);
  const clearLocalSearch = () => _setSearch('');

  const initSearchRef = useRef(false);

  useEffect(() => {
    if (initSearchRef.current) {
      setSearch(debouncedSearch);
      return;
    }
    initSearchRef.current = true;
  }, [debouncedSearch, setSearch]);

  return (
    <StickyHeader>
      <ListStickyHeaderOuterSectionWrapper>
        <ListStickyHeaderSectionWrapper>
          <HeaderSection style={{ position: 'relative' }}>
            {showCalendarDatePicker && (
              <CalendarDatePicker
                activeView={activeView}
                setShowCalendarDatePicker={setShowCalendarDatePicker}
                handleCalendarPick={handleCalendarPick}
                currentTime={currentTime}
                highlightDates={weeklyDays}
              />
            )}
            <Title
              style={{ marginLeft: 0 }}
              isClickable={activeView !== CALENDAR_VIEW_TYPES.yearly}
              data-testid="calendarTimeShowTitle"
              onClick={() => {
                if (activeView === CALENDAR_VIEW_TYPES.yearly) return;
                setShowCalendarDatePicker((prevState) => !prevState);
              }}
            >
              {showTime()}
            </Title>
            <WowDisplayFlex>
              {showPrevArrow ? (
                <WowIcon
                  className="icon icon-arrowleft-02"
                  size="25"
                  color={darkGray}
                  display="flex"
                  spaceRight="0"
                  clickable
                  onClick={() => handleArrows()}
                  data-testid="arrowPrev"
                />
              ) : null}
              {showNextArrow ? (
                <WowIcon
                  className="icon icon-arrowright-02"
                  size="25"
                  color={darkGray}
                  display="flex"
                  clickable
                  onClick={() => handleArrows(true)}
                  data-testid="arrowNext"
                />
              ) : null}
            </WowDisplayFlex>

            {activeView === CALENDAR_VIEW_TYPES.yearly ? (
              <Search
                value={_search}
                onChange={(e) => _setSearch(e.target.value)}
                onClear={clearLocalSearch}
              />
            ) : (
              <TodayButton onClick={setToday}>
                <FormattedMessage id="today" />
              </TodayButton>
            )}

            {(activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
              activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) && (
              <>
                <div style={{ marginTop: 5, marginLeft: 15, marginRight: 10 }}>
                  <span style={{ position: 'relative', top: 2 }}>
                    <Switch
                      value={showWeekend}
                      onChange={() => setShowWeekend((prevState) => !prevState)}
                      data-testid="weeklyCalendarShowWeekendSwitch"
                    />
                  </span>
                  <div
                    style={{
                      position: 'relative',
                      bottom: 4,
                      color: filtersAdvancedGray,
                      fontSize: '0.9em',
                      paddingLeft: 8,
                      display: 'inline-block',
                    }}
                  >
                    {intl.formatMessage({ id: 'show_weekend' })}
                  </div>
                </div>
              </>
            )}
          </HeaderSection>
          <HeaderSection>
            <FilterActions
              {...filterActionsConfig}
              clearLocalSearch={clearLocalSearch}
            />

            {activeView !== CALENDAR_VIEW_TYPES.yearly && (
              <ButtonWithIcon
                onClick={handleUnscheduledMenuOpen}
                isActive={isUnscheduledMenuActive}
              >
                <WowIcon
                  className="icon icon-plus"
                  color={isUnscheduledMenuActive ? white : purple500}
                  spaceRight="5"
                />
                <FormattedMessage id="unscheduled" />
              </ButtonWithIcon>
            )}

            <Space width="10" />

            {activeView === CALENDAR_VIEW_TYPES.yearly ? (
              <Export isDisabled={isExportDisabled} />
            ) : null}

            <MainDropdown
              options={MAIN_MENU_OPTIONS}
              value={activeView}
              onChange={(value) => {
                // hack used to set search param as empty
                // so search for yearly params is populated from config
                if (value === CALENDAR_VIEW_TYPES.yearly) {
                  history.replace(pathname);
                }
                setActiveView(value);
              }}
              position="right"
              width="240px"
            />
          </HeaderSection>
        </ListStickyHeaderSectionWrapper>

        {CALENDAR_FILTERS_PERSIST_KEY[activeView] ? (
          <ListFilters {...filtersConfig} />
        ) : null}

        {activeView === CALENDAR_VIEW_TYPES.yearly ? (
          <WowDisplayFlex justify="flex-end" overflow="visible">
            <Sort
              onChange={setSortBy}
              onOrderChange={setSortDirection}
              options={YEARLY_SORT_OPTIONS.map((sortOption) => ({
                value: sortOption.value,
                label: intl.formatMessage({
                  id: sortOption.label,
                  defaultMessage: dict[sortOption.label],
                }),
              }))}
              order={sortDirection}
              selectedOption={sortBy}
            />
          </WowDisplayFlex>
        ) : null}
        <Space height="10" />
      </ListStickyHeaderOuterSectionWrapper>
    </StickyHeader>
  );
};

export default Header;
