export const pageStatus = {
  initial: 'initial',
  loading: 'loading',
  idle: 'idle',
  error: 'error',
};

export const statuses = [
  { value: 'all', label: 'status_all' },
  { value: 'active', label: 'active' },
  { value: 'paused', label: 'paused' },
  { value: 'archived', label: 'archived' },
];

export const sortOptions = [
  {
    value: 'title',
    label: 'title',
  },
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'upcoming',
    label: 'upcoming',
  },
];

export const INIT_QUERY_PARAMS = {
  page: 1,
  paginate_by: '20',
  sort_by: 'updated',
  sort_direction: 'desc',
};

export const ADVANCED_FILTERS = {
  assignees: [],
  assignees_companies: [],
  assignees_externals: [],
  watchers: [],
  watchers_companies: [],
  watchers_externals: [],
  replacements: [],
  categories: [],
  workspaces: [],
  assets: [],
  asset_system_ids: [],
  asset_group_ids: [],
  equipment: [],
};

export const INIT_FILTERS = {
  status: 'all',
  priority_id: '',
  sort_direction: 'desc',
  sort_by: 'updated',
  include_sub_ws: 0,
  ...ADVANCED_FILTERS,
};

export const SUPPORTED_PARAMS_NEW = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'title', 'upcoming'],
  },
  sort_direction: {
    defaultValue: 'desc',
    allowedValues: ['asc', 'desc'],
  },
  status: {
    defaultValue: null,
    allowedValues: null,
  },
  include_sub_ws: {
    defaultValue: null,
    allowedValues: ['0', '1'],
  },
  users_any_role: {
    defaultValue: null,
    allowedValues: null,
  },
  collabs_any_role: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  priority_id: {
    defaultValue: null,
    allowedValues: ['5', '4', '3', '2'],
  },
  priority_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspaces: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_group_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_system_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  categories: {
    defaultValue: null,
    allowedValues: null,
  },
  filter_by_job_positions: {
    defaultValue: [],
    allowedValues: null,
  },
  equipments_ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const SUPPORTED_PARAMS = {
  schedule_ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const CUSTOMER_SUPPORT_CONFIG = {
  entity: 'schedules',
  videoId: 'DY8SG9Zzuz8',
};

export const PERSISTED_FILTERS_KEY = 'schedule';

export const EMPTY_SCREEN_SETTINGS = {
  icon: 'icon-scheduling',
  keys: {
    title: 'what_is_schedules',
    text: 'no_schedules_text',
  },
};
