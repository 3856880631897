import { FormattedMessage } from 'react-intl';
import { FilterActionButton } from './styles';
import useFiltersReset from '../../../../../../../components_new/ListFilters/hooks/useFiltersReset';
import Space from '../../../../../../../components_new/Space';
import { newRed } from '../../../../../../../config/colors';

const FilterActions = ({
  persistedFiltersKey,
  initQueryParams,
  clearLocalSearch,
}) => {
  const { shouldShowResetFilters, resetFilters } = useFiltersReset(
    persistedFiltersKey,
    initQueryParams
  );

  const handleClear = () => {
    clearLocalSearch();
    resetFilters();
  };

  return (
    <>
      {shouldShowResetFilters ? (
        <>
          <FilterActionButton color={newRed} onClick={handleClear}>
            <FormattedMessage id="reset_all_filters" />
          </FilterActionButton>
          <Space width="15" />
        </>
      ) : null}
    </>
  );
};

export default FilterActions;
